<template>
  <div class="card-header">
    <icon
      fixed-width
      :icon="icon"
      class="card-header__icon"
    />
    <h3
      data-jest="title"
      class="card-header__title"
    >
      {{ title }}
    </h3>
    <information-button
      data-jest="button"
      @click="emitClick"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import InformationButton from "@/components/information-button.vue";

export default defineComponent({
	emits: ["click"],
  components: {
    InformationButton
  },
  props: {
    icon: {
      type: [String, Array],
      required: true
    },
    title: {
      type: String,
      required: true
    }
  },
  methods: {
    emitClick() {
      this.$emit("click");
    }
  }
});
</script>

<style lang="scss" scoped>
.card-header {
  display: flex;
  align-items: center;
  max-height: 2rem;
  &__button {
    box-shadow: 0 0.125rem 0.625rem rgba(0, 0, 0, 0.07);
    transition: box-shadow 0.1s ease-in-out;
    height: 1.5rem !important;
    width: 1.5rem !important;
    &:hover {
      @include cursorHover {
        box-shadow: 0 0.1875rem 0.75rem rgba(0, 0, 0, 0.15);
      }
    }
    &:before {
      background: transparent;
    }
    &:focus-visible {
      box-shadow: 0 0 0.0625rem 0.0625rem $aqua,
        0 0.125rem 0.875rem scale-color($aqua, $alpha: -60%);
    }
  }
  &__button-icon {
    font-size: 0.75rem;
    color: $aqua;
  }
  &__icon {
    font-size: 1.25rem;
    color: $nearly-black;
    margin-right: 0.75rem;
  }
  &__title {
    flex-grow: 1;
    font-weight: 500;
    font-size: 1.125rem;
    margin-right: 0.75rem;
  }
}
</style>

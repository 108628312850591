import { actions } from "./actions";
import { getters } from "./getters";
import { getOverviewInitialState, mutations } from "./mutations";

const initialState = getOverviewInitialState();

export default {
  actions,
  getters,
  mutations,
  state: initialState,
};

<template>
  <SideDialog
    v-if="showDepositDialog"
    :model-value="showDepositDialog"
    :title="$t('depositTitle')"
    data-jest="dialog"
    :show-back-button="currentMethod !== undefined"
    @update:model-value="resetAndClose"
    @back="currentMethod = undefined"
  >
    <div class="dialog">
      <template v-if="currentMethod === undefined">
        <label class="dialog__subheader">{{
          $t("portfolio.overview.deposit.side-dialog.choosePaymentMethod")
        }}</label>
        <template v-if="hasBankAccount">
          <CommonButton
            data-cy="autogiro"
            class="dialog__button"
            @click="currentMethod = PaymentMethod.Autogiro"
          >
            {{ $t("bankTransfer") }}
          </CommonButton>
          <p class="dialog__description">
            {{ $t("portfolio.overview.deposit.side-dialog.autogiroDescription") }}
          </p>
        </template>
        <template v-if="!isCompany">
          <CommonButton
            data-cy="swish"
            class="dialog__button"
            @click="currentMethod = PaymentMethod.Swish"
          >
            Swish
          </CommonButton>
          <p class="dialog__description">
            {{ $t("portfolio.overview.deposit.side-dialog.swishDescription") }}
          </p>
        </template>
      </template>
      <template v-if="currentMethod === PaymentMethod.Autogiro">
        <Autogiro @close="resetAndClose" />
      </template>
      <template v-if="currentMethod === PaymentMethod.Swish">
        <Swish @close="resetAndClose" />
      </template>
    </div>
  </SideDialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import Autogiro from "./autogiro-deposit.vue";
import Swish from "./swish-deposit.vue";
import SideDialog from "@/components/dialog/side-dialog.vue";
import CommonButton from "@/components/button.vue";
import { PortfolioMutation } from "@/store/portfolio/mutations";

enum PaymentMethod {
  Autogiro,
  Swish,
}
export default defineComponent({
  components: {
    Autogiro,
    CommonButton,
    SideDialog,
    Swish,
  },
  data() {
    return { PaymentMethod, currentMethod: undefined as PaymentMethod | undefined };
  },
  computed: {
    ...mapGetters(["isCompany", "hasBankAccount", "isTestUser"]),
    showDepositDialog: {
      set(value: boolean) {
        this.$store.commit(PortfolioMutation.setShowDepositDialog, value);
      },
      get(): boolean {
        return this.$store.state.portfolioStore.showDepositDialog;
      },
    },
  },
  methods: {
    resetAndClose() {
      this.currentMethod = undefined;
      this.showDepositDialog = false;
    },
  },
});
</script>

<style lang="scss" scoped>
.dialog {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  height: 100%;
  &__subheader {
    font-size: 1.125rem;
    text-align: center;
    font-family: $heading-font-family;
  }
  &__description {
    font-size: 0.875rem;
    opacity: 0.8;
  }
  &__button {
    margin-top: 1rem;
  }
}
</style>

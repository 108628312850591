import DOMPurify from "dompurify";

export function sanitizeAndParseHTML(input: string): string {
  // Decode escaped HTML entities
  const decodeHTMLEntities = (text: string): string => {
    const parser = new DOMParser();
    const decodedDocument = parser.parseFromString(text, "text/html");
    return decodedDocument.documentElement.textContent || "";
  };

  const decodedHTML = decodeHTMLEntities(input);
  const sanitizedHTML = DOMPurify.sanitize(decodedHTML);

  return sanitizedHTML;
}

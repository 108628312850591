<template>
  <div class="theme">
    <single-choice
      :alternatives="navigationAlternatives"
      information
      @click="goToNext"
      @showInformation="showDialog = true"
    >
      <question-text-section
        :heading="$t('common-signup.questions.preferences.theme.heading')"
        :body="$t('common-signup.questions.preferences.theme.body')"
        progress
      />

      <div class="theme__content">
        <v-btn
          variant="text"
          icon
          :color="softBlue"
          class="theme__stepper"
          :disabled="carouselIndex === 0"
          data-cy="stepper-left"
          @click="decrement"
        >
          <icon :icon="['fal', 'chevron-left']" />
        </v-btn>
        <v-carousel
          v-model="carouselIndex"
          class="theme__carousel"
          height="auto"
          data-cy="risk_carousel"
          :show-arrows="false"
          :continuous="false"
          :hide-delimiter-background="true"
          @update:model-value="change"
        >
          <v-carousel-item
            v-for="(focus, index) in portfolioThemes"
            :key="index"
            class="theme__risk-carousel-item"
          >
            <v-card
              style="
                width: 13.75rem;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 1.5rem;
                row-gap: 0.5rem;
              "
            >
              <div style="font-weight: bold; font-size: 1.125rem">
                {{ focus.name }}
              </div>
              <div
                v-if="descriptionText(focus.tiedAgentPortfolioThemeType)"
                style="text-align: center; font-size: 0.7rem"
              >
                {{ descriptionText(focus.tiedAgentPortfolioThemeType) }}
              </div>
              <v-divider style="width: 100%; border-color: #2b2b2b; opacity: 0.33" />

              <div style="width: 100%; display: flex; flex-direction: column; row-gap: 0.2rem">
                <div class="theme__row">
                  <div style="font-size: 1.125rem">
                    {{ $t("markets.Sweden") }}
                  </div>
                  <div style="font-weight: bold; font-size: 1.125rem">
                    {{ focus.swedenFraction * 100 }} %
                  </div>
                </div>
                <div class="theme__row">
                  <div style="font-size: 1.125rem">
                    {{ $t("markets.Europe") }}
                  </div>
                  <div style="font-weight: bold; font-size: 1.125rem">
                    {{ focus.europeFraction * 100 }} %
                  </div>
                </div>
                <div class="theme__row">
                  <div style="font-size: 1.125rem">
                    {{ $t("markets.Us") }}
                  </div>
                  <div style="font-weight: bold; font-size: 1.125rem">
                    {{ focus.usFraction * 100 }} %
                  </div>
                </div>
              </div>
              <v-divider style="width: 100%; border-color: #2b2b2b; opacity: 0.33" />
              <div style="display: flex; flex-direction: column; row-gap: 0; width: 100%">
                <div class="theme__row">
                  <div style="font-size: 0.75rem">
                    {{ $t("managementFee") }}
                  </div>
                  <div style="font-size: 0.875rem">{{ focus.managementFeeFraction * 100 }} %</div>
                </div>
                <div
                  class="theme__row"
                  style="margin-top: -0.3rem"
                >
                  <div style="font-size: 0.6875rem">
                    {{ $t("perYear") }}
                  </div>
                </div>
              </div>
            </v-card>
          </v-carousel-item>
        </v-carousel>
        <v-btn
          variant="text"
          icon
          :color="softBlue"
          class="theme__stepper"
          :disabled="carouselIndex === portfolioThemes.length - 1"
          data-cy="stepper-right"
          @click="increment"
        >
          <icon :icon="['fal', 'chevron-right']" />
        </v-btn>
      </div>
      <side-dialog
        v-model="showDialog"
        :title="$t('common-signup.questions.preferences.theme.infoHeading')"
      >
        <div class="theme__side-dialog">
          <div
            v-for="(section, i) in infoSections"
            :key="i"
          >
            <p class="theme__side-dialog--heading">
              {{ section.heading }}
            </p>
            <p
              :class="[
                'theme__side-dialog--text',
                { 'theme__side-dialog--bullet-list': section.bulletList }
              ]"
            >
              {{ section.text }}
            </p>
          </div>
        </div>
      </side-dialog>
    </single-choice>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { SignupNavigationAction } from "@/store/signup-navigation/actions";
import SideDialog from "@/components/dialog/side-dialog.vue";
import { Market, TiedAgentPortfolioTheme, TiedAgentPortfolioThemeType } from "@/clients";
import { NavigationAlternative } from "@/types/signup";
import { softBlue } from "@/styles/colors";
import { InfoSection } from "@/types";
import Question from "@/views/signup/templates/question";
import QuestionTextSection from "../../../signup/question-text-section.vue";
import SingleChoice from "../../../signup/templates/single-choice.vue";

interface MarketFocusAlternative {
  label: string;
  key: number;
}

export default defineComponent({
  emits: ["change"],
  components: {
    QuestionTextSection,
    SingleChoice,
    SideDialog
  },
  extends: Question,
  props: {
    portfolioThemes: { type: Array as PropType<TiedAgentPortfolioTheme[]>, required: true },
    selectedPortfolioTheme: { type: TiedAgentPortfolioTheme }
  },
  data: () => ({
    showDialog: false,
    carouselIndex: 0,
    Market,
    softBlue
  }),
  computed: {
    infoSections(): InfoSection[] {
      return (this.portfolioThemes as TiedAgentPortfolioTheme[]).map(
        (t: TiedAgentPortfolioTheme) =>
          ({ heading: t.name, text: this.infoText(t.tiedAgentPortfolioThemeType) }) as InfoSection
      );
    },
    navigationAlternatives(): NavigationAlternative[] {
      return [
        {
          text: `${this.$t("choose")} ${
            (this.portfolioThemes as TiedAgentPortfolioTheme[])[this.carouselIndex].name
          }`,
          key: "next"
        }
      ];
    }
  },
  created(): void {
    if (this.selectedPortfolioTheme !== undefined) {
      // Spin carousel to already selected theme's index
      this.carouselIndex = this.portfolioThemes.findIndex(
        (x: any) => x.name === this.selectedPortfolioTheme?.name
      );
      // Manually trigger change because v-carousel event is only triggered by user interaction with actual component
      this.change();
    } else {
      // Simulate an initial selection so parent can take action
      this.change();
    }
  },
  methods: {
    descriptionText(type: TiedAgentPortfolioThemeType) {
      switch (type) {
        case TiedAgentPortfolioThemeType.TestGreen:
          return this.$t("tiedAgentPortfolioThemes.TestGreen.description");
        case TiedAgentPortfolioThemeType.TestStandard:
          return this.$t("tiedAgentPortfolioThemes.TestStandard.description");
        default:
          return "";
      }
    },
    infoText(type: TiedAgentPortfolioThemeType) {
      switch (type) {
        case TiedAgentPortfolioThemeType.TestGreen:
          return this.$t("tiedAgentPortfolioThemes.TestGreen.description");
        case TiedAgentPortfolioThemeType.TestStandard:
          return this.$t("tiedAgentPortfolioThemes.TestStandard.description");
        default:
          return "";
      }
    },
    increment() {
      this.carouselIndex += 1;
      // Manually trigger change because v-carousel event is only triggered by user interaction with actual component
      this.change();
    },
    decrement() {
      this.carouselIndex -= 1;
      // Manually trigger change because v-carousel event is only triggered by user interaction with actual component
      this.change();
    },
    change(): void {
      this.$emit("change", this.portfolioThemes[this.carouselIndex]);
    },
    goToNext() {
      this.$store.dispatch(SignupNavigationAction.goToNextStep, this.$router);
    }
  }
});
</script>

<style lang="scss" scoped>
$text-max-width: 21.25rem;

.theme {
  width: 100%;
  height: 100%;
  &__row {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  &__carousel {
    :deep(.v-carousel__controls__item) {
      color: $soft-blue;
      margin: 0 0.1rem;
      width: 0.75rem;
      height: 0.75rem;
      &.v-btn--active {
        .v-icon {
          opacity: 1;
        }
      }
      .v-icon {
        font-size: 0.5rem !important;
        width: 0.75rem !important;
        height: 0.75rem !important;
      }
    }
    :deep(.v-carousel__controls) {
      position: relative;
      height: auto;
    }
    :deep(.v-carousel__item) {
      margin: 1rem;
      box-shadow: 0 0.1875rem 1.25rem rgba(96, 96, 96, 0.1);
    }
  }
  &__content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 21rem;
    margin: auto;
  }
  &__presentation {
    display: flex;
    margin-bottom: 2rem;
  }
  &__risk-carousel-item {
    margin: auto;
  }
  &__choices-carousel-item {
    width: $text-max-width;
    max-width: 100%;
    margin: auto;
  }

  &__side-dialog {
    padding: 1rem;
    &--description {
      font-weight: 300;
    }
  }
  &__wrapper {
    max-width: 20em;
    background: transparent !important;
    margin: 0 auto;
    padding-top: 1rem;
    @include medium-up {
      padding-top: 2rem;
    }
  }
  &__alternative {
    display: grid;
    grid-template-columns: auto 1fr auto;
    width: 100%;
    position: relative;
    border-radius: 0.5rem;
    padding: 0.5625rem;
    align-items: center;
  }
  &__alternative-text {
    font-size: 1.125rem;
    font-family: $heading-font-family;
    flex: 1;
    grid-column: 2;
    padding: 0 2.25rem 0 1rem;
    @include medium-up {
      font-size: 1.25rem;
    }
  }
  &__percentage {
    grid-column: 3;
    font-size: 1.125rem;
    font-family: $heading-font-family;
    color: $nearly-black;
    font-weight: 600;
  }

  &__label {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    border-radius: 0.5rem;
    transition: background 0.1s ease-in-out;
    &:hover {
      @include cursorHover {
        background: rgba(0, 0, 0, 0.04);
      }
    }
  }
  &__ripple {
    color: rgba(0, 0, 0, 0.2);
  }
  &__side-dialog {
    padding: 1rem;
    &--heading {
      font-size: 1rem;
      font-weight: 600;
    }
    &--description {
      font-weight: 300;
    }
  }
  &__stepper {
    font-size: 1.5rem;
    outline: none;
    &--right {
      justify-self: flex-end;
    }
    &:before {
      background: transparent;
      transition: all 0.1s ease-in-out;
    }
    &:hover:before {
      @include cursorHover {
        background: black;
      }
    }
    &:focus-visible {
      box-shadow:
        0 0 0.0625rem 0.0625rem $aqua,
        0 0.125rem 0.875rem scale-color($aqua, $alpha: -60%);
    }
  }
}
:deep(.theme--light.v-input--selection-controls.v-input--is-disabled:not(.v-input--indeterminate))
  .v-icon {
  color: rgba(0, 0, 0, 0.54) !important;
}
</style>

<template>
  <side-dialog
    :model-value="modelValue"
    :title="$t('message')"
    @update:model-value="(value) => $emit('update:model-value', value)"
  >
    <div class="messages-dialog__wrapper">
      <div class="messages-dialog__header">
        <p>{{ portfolio }}</p>
        <p>{{ getFormattedDate(date) }}</p>
      </div>
      <p class="messages-dialog__subject">
        {{ subject }}
      </p>
      <!-- eslint-disable-next-line vue/no-v-html -->
      <div
        v-if="isHtml"
        class="messages-dialog__content"
        v-html="content"
      />
      <p
        v-if="!isHtml"
        class="messages-dialog__content"
      >
        {{ content }}
      </p>
      <div class="messages-dialog__button-wrapper">
        <common-button @click="$emit('update:model-value')">
          {{ $t("close") }}
        </common-button>
      </div>
    </div>
  </side-dialog>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import SideDialog from "@/components/dialog/side-dialog.vue";
import CommonButton from "@/components/button.vue";
import { Message } from "@/clients/index";
import { sanitizeAndParseHTML } from "@/utils/html-parser"

export default defineComponent({
  emits: ["update:model-value"],
  components: { SideDialog, CommonButton },
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    message: {
      type: Object as PropType<Message | undefined>
    }
  },
  computed: {
    portfolio(): string {
      return this.message && this.message.portfolio ? this.message.portfolio : "";
    },
    date(): Date | undefined {
      return this.message && this.message.date ? this.message.date : undefined;
    },
    subject(): string {
      return this.message && this.message.subject ? this.message.subject : "";
    },
    content(): string {
      if (this.isHtml) {
        const sanitizedHTMLString = sanitizeAndParseHTML(this.message?.content ?? "");

        if (this.subject.includes("värdeminskning")) {
          return sanitizedHTMLString
            .replaceAll("<br>", "")
            .replaceAll("Sigmastockss", "Sigmastocks");
        }

        return sanitizedHTMLString;
      }
      return this.message && this.message.content ? this.message.content : "";
    },
    isHtml(): boolean {
      return !!this.message && this.message.isHtml;
    }
  },
  methods: {
    getFormattedDate(date: Date | undefined): string {
      if (date) return date.toLocaleDateString("sv-SE");
      return "";
    }
  }
});
</script>

<style lang="scss">
.messages-dialog {
  &__wrapper {
    display: flex;
    flex-direction: column;
    padding: 1.5rem;
  }
  &__header {
    display: flex;
    justify-content: space-between;
  }
  &__subject {
    font-weight: bold;
    word-wrap: break-word;
  }
  &__close-button {
    margin-top: 2rem;
  }
  &__content {
    white-space: pre-line;
    word-wrap: break-word;
  }
  &__button-wrapper {
    margin-top: 2rem;
    display: flex;
    justify-content: center;
  }
}
</style>

<template>
  <v-btn
    variant="flat"
    data-jest="button"
    icon
    class="information-button"
    @click="$emit('click')"
  >
    <icon
      fixed-width
      :icon="['fal', 'info-circle']"
      class="information-button__icon"
    />
  </v-btn>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  emits: ["click"],
});
</script>

<style lang="scss" scoped>
.information-button {
  width: 2.75rem !important;
  height: 2.75rem !important;
  margin-right: -0.5rem;
  &:focus-visible {
    box-shadow:
      0 0 0.0625rem 0.0625rem $aqua,
      0 0.125rem 0.875rem scale-color($aqua, $alpha: -60%);
    &:before {
      background-color: white;
    }
  }
  &__icon {
    font-size: 1.25rem;
    color: rgba($nearly-black, 0.8);
  }
}
</style>

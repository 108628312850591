<template>
  <v-btn
    icon
    class="add-button"
    :disabled="disabled"
    variant="flat"
    @click="$emit('click')"
  >
    <icon
      :class="[
        'add-button__icon',
        { 'add-button__icon--edit': edit },
        { 'add-button__disabled': disabled }
      ]"
      :icon="['fal', edit ? 'save' : 'plus']"
    />
  </v-btn>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  emits: ["click"],
  props: {
    edit: {
      type: Boolean,
      required: true
    },
    disabled: {
      type: Boolean
    }
  }
});
</script>

<style lang="scss" scoped>
.add-button {
  margin: -1.25rem -0.75rem -1.25rem 0;
  width: 2.75rem !important;
  height: 2.75rem !important;
  &:focus-visible {
    box-shadow:
      0 0 0.0625rem 0.0625rem $aqua,
      0 0.125rem 0.875rem scale-color($aqua, $alpha: -60%);
    &:before {
      background-color: white;
    }
  }
  &__icon {
    color: $nearly-black;
    font-size: 1.25rem;
    opacity: 0.63;
    &--edit {
      opacity: 1;
    }
  }
  &__disabled {
    opacity: 0.66;
  }
}
</style>

<template>
  <v-dialog
    :model-value="showDialog"
    :transition="
      $vuetify.display.smAndDown ? 'slide-y-reverse-transition' : 'slide-x-reverse-transition'
    "
    :content-class="
      !$vuetify.display.smAndDown
        ? 'side-dialog__content side-dialog__content--right'
        : 'side-dialog__content'
    "
    :fullscreen="$vuetify.display.smAndDown"
    @update:model-value="(value: boolean) => (showDialog = value)"
  >
    <!-- We need v-sheet to make v-dialog scrollable.
      See open issue https://github.com/vuetifyjs/vuetify/issues/17381 -->
    <v-sheet style="height: 100%">
      <div class="side-dialog__header">
        <div class="side-dialog__back-button">
          <v-btn
            v-if="showBackButton"
            variant="text"
            icon
            width="2.75rem"
            height="2.75rem"
            class="side-dialog__button"
            data-cy="side-dialog-button"
            @click="$emit('back')"
          >
            <icon
              :icon="['fal', 'chevron-left']"
              size="lg"
            />
          </v-btn>
        </div>
        <h2
          v-if="title"
          class="side-dialog__title"
        >
          {{ title }}
        </h2>
        <v-btn
          variant="text"
          icon
          width="2.75rem"
          height="2.75rem"
          class="side-dialog__button"
          data-cy="side-dialog-button"
          @click="showDialog = false"
        >
          <icon
            :icon="['fal', 'times']"
            size="lg"
          />
        </v-btn>
      </div>
      <div class="side-dialog__slot">
        <slot />
      </div>
    </v-sheet>
  </v-dialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: undefined,
    },
    showBackButton: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["back", "update:model-value", "input"],
  computed: {
    showDialog: {
      get(): boolean {
        return this.modelValue;
      },
      set(value: boolean): void {
        this.$emit("update:model-value", value);
        this.$emit("input", value);
      },
    },
  },
});
</script>

<style lang="scss">
.side-dialog {
  &__button {
    grid-column: 3;
    &:focus-visible {
      box-shadow:
        0 0 0.0625rem 0.0625rem $aqua,
        0 0.125rem 0.875rem scale-color($aqua, $alpha: -60%);
      &:before {
        background-color: white;
      }
    }
  }
  &__content {
    z-index: 15;
    border-radius: 0 !important;
    background: white;
    display: flex;
    flex-direction: column;
    &--right {
      max-width: 27.5rem !important;
      position: absolute;
      right: 0;
      height: 100%;
      margin: 0 !important;
      max-height: none !important;
    }
  }
  &__header {
    width: 100%;
    justify-items: center;
    align-items: center;
    height: 4.25rem;
    min-height: 4.25rem;
    border-bottom: solid 0.0625rem rgba($nearly-black, 0.2);
    display: flex;
    justify-content: space-between;
    padding: 0 1rem;
  }
  &__slot {
    flex: 1;
    height: 100%;
    padding-bottom: 2rem;
  }
  &__title {
    grid-column: 2;
    color: rgba($nearly-black, 0.8);
    font-size: 1.25rem;
    font-weight: 400;
  }
  &__back-button {
    height: 2.75rem;
    width: 2.75rem;
  }
}
</style>

<template>
  <SingleChoice
    information
    :alternatives="alternatives"
    :color="buttonColor"
    :disabled="!seenAllInfo"
    class="sustainability"
    @click="buttonClick"
    @show-information="() => (showDialog = true)"
  >
    <QuestionTextSection
      progress
      :heading="$t('esg.esg.esgTitle')"
    />
    <InfoCarousel
      :items="4"
      @done="seenAllInfo = true"
    >
      <v-carousel-item class="sustainability__carousel_item">
        <SustainabilityCarouselCard>
          <div>
            När man pratar om hållbarhet inom sparande används ofta uttrycket ESG som står för
            Environmental, Social och Governance.
          </div>
          <br>
          <div>
            Vilket på svenska blir <i>miljö</i>, <i>socialt ansvar</i> och <i>bolagsstyrning</i>.
          </div>
        </SustainabilityCarouselCard>
      </v-carousel-item>
      <v-carousel-item class="sustainability__carousel_item">
        <SustainabilityCarouselCard>
          <div>
            Som portföljförvaltare ska vi ta reda på dina preferenser kring hållbarhet och skapa en
            portfölj som passar dig.
          </div>
          <br>
          <div>
            Och för att detta ska bli lättare har EU tagit fram ett nytt gemensamt regelverk.
            <span style="font-size: 0.8rem; opacity: 0.8"><i>Läs mer om reglerna under infon.</i></span>
          </div>
        </SustainabilityCarouselCard>
      </v-carousel-item>
      <v-carousel-item class="sustainability__carousel_item">
        <SustainabilityCarouselCard>
          <div>
            EU:s regler definierar vad som får klassas som en hållbar investering, vad vi måste
            fråga dig som kund om, och hur bolag som erbjuder hållbara sparprodukter eller tjänster
            ska rapportera sitt hållbarhetsarbete.
          </div>
        </SustainabilityCarouselCard>
      </v-carousel-item>
      <v-carousel-item class="sustainability__carousel_item">
        <SustainabilityCarouselCard>
          <div>
            Syftet är att vi alla ska ha en gemensam bild av vad hållbarhet och hållbart sparande
            innebär.
          </div>
          <div>
            På så sätt blir det enklare för dig som kund att jämföra olika spartjänster och
            produkter.
          </div>
        </SustainabilityCarouselCard>
      </v-carousel-item>
    </InfoCarousel>

    <SectionedSideDialog
      v-model="showDialog"
      title="EU:s regler"
      :info-sections="infoSections"
    />
  </SingleChoice>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import SingleChoice from "../../../signup/templates/single-choice.vue";
import InformationTextSection from "../../../signup/information-text-section.vue";
import QuestionTextSection from "../../../signup/question-text-section.vue";
import SustainabilityCarouselCard from "./sustainability-carousel-card.vue";
import { SignupNavigationAction } from "@/store/signup-navigation/actions";
import SideDialog from "@/components/dialog/side-dialog.vue";
import type { InfoSectionMultiple } from "@/types";
import Question from "@/views/signup/templates/question";
import SectionedSideDialog from "@/components/dialog/sectioned-side-dialog.vue";
import { softBlue } from "@/styles/colors";
import InfoCarousel from "@/components/info-carousel.vue";

export default defineComponent({
  components: {
    InformationTextSection,
    QuestionTextSection,
    SingleChoice,
    SideDialog,
    SustainabilityCarouselCard,
    SectionedSideDialog,
    InfoCarousel,
  },
  extends: Question,
  props: {
    buttonColor: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      showDialog: false,
      carouselIndex: 0,
      softBlue,
      seenAllInfo: false,
    };
  },
  computed: {
    alternatives(): any {
      return [
        {
          text: this.$t("next"),
          key: "next",
        },
      ];
    },
    infoSections(): InfoSectionMultiple[] {
      return [
        {
          subSections: [
            {
              texts: [
                "Under 2022 och 2023 har det i omgångar kommit nya regler från EU angående hållbara investeringar.",
                "Syftet är att göra det enklare för dig som sparare att identifiera, jämföra och välja hållbara investeringar. Detta genom att see till att vi alla har  samma bild av vad hållbarhet och hållbart sparande är.",
                "Det handlar om taxonomin, SFDR, MiFID2 och IDD. Vi går snabbt igenom vad de olika dokumenten handlar om här, och du kan läsa mer om ämnet på vår hemsida.",
              ],
            },
          ],
        },
        {
          heading: "EU:s taxonomiförordning",
          subSections: [
            {
              texts: [
                "En ny standard som ska hjälpa oss att avgöra om en ekonomisk verksamhet är miljömässigt hållbar.",
                "Det är den om den bidrar till något av EU:s miljömål, inte skadar något av hållbarhetsmålen och styrs på ett bra sätt.",
              ],
            },
          ],
        },
        {
          heading: "SFDR (Sustainable Finance Disclosure Regulation eller Disclosureförordningen)",
          subSections: [
            {
              texts: [
                "En annan standard för att avgöra om en investering är hållbar. Har en liknande definition som taxonomin, men SFDR inbegriper också bolag som bidrar till sociala hållbarhetsmål.",
                "SFDR innehåller också regler för hur fondbolag ska informera om hållbarhet i sina fonder (de olika hållbara kategorierna kallas artikel 8 eller artikel 9 fonder).",
              ],
            },
          ],
        },
        {
          heading: "MiFiD 2",
          subSections: [
            {
              texts: [
                "· MiFID2 (Markets in Financial Instruments Directive) - EU:s direktiv om värdepappersmarknaden",
                "Direktiv som definierar hållbarhetspreferenser, dvs vilken information rådgivare och förvaltare ska inhämta från dig som kund för att kunna ge dig sparande eller råd som stämmer med vad du vill ha.",
              ],
            },
          ],
        },
      ];
    },
  },
  methods: {
    buttonClick() {
      this.$store.dispatch(SignupNavigationAction.goToNextStep, this.$router);
    },
    increment() {
      this.carouselIndex += 1;
    },
    decrement() {
      this.carouselIndex -= 1;
    },
  },
});
</script>

<style lang="scss" scoped>
.sustainability {
  display: flex;
  align-items: center;

  &__content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 21rem;
    margin: auto;
    margin-top: 1rem;
  }

  &__carousel {
    width: 15rem;
    :deep(.v-carousel__controls) {
      color: $soft-blue;
      bottom: -0.5rem;
      @include medium-up {
        bottom: -1.5rem;
      }
      .v-btn {
        width: 0.75rem;
        height: 0.75rem;
        opacity: 0.5;
        &--active {
          opacity: 1;
        }
      }
      .v-icon {
        font-size: 0.5rem !important;
        width: 0.75rem !important;
        height: 0.75rem !important;
      }
    }
    &:deep(.v-carousel__item) {
      margin: 1rem;
    }
  }
  &__stepper {
    font-size: 1.5rem;
    outline: none;
    &--right {
      justify-self: flex-end;
    }
    &:before {
      background: transparent;
      transition: all 0.1s ease-in-out;
    }
    &:hover:before {
      @include cursorHover {
        background: black;
      }
    }
    &:focus-visible {
      box-shadow:
        0 0 0.0625rem 0.0625rem $aqua,
        0 0.125rem 0.875rem scale-color($aqua, $alpha: -60%);
    }
  }
}
</style>

<template>
  <div
    :class="[
      'multiple-choice',
      { 'multiple-choice--large': alternatives.length > 4, 'multiple-choice--wide': wide }
    ]"
    :style="{ marginTop: marginTop }"
  >
    <div
      v-for="alternative in alternatives"
      :key="alternative.key"
      :class="[
        'multiple-choice__alternative',
        { 'multiple-choice__alternative--two-col': alternative.asFreeText }
      ]"
    >
      <label
        v-if="!alternative.asFreeText"
        v-ripple="{ class: 'multiple-choice__ripple' }"
        :for="`checkbox${alternative.key}`"
        class="multiple-choice__label"
      />

      <div
        v-if="alternative.icon"
        class="multiple-choice__icon-wrapper"
      >
        <icon
          fixed-width
          :icon="alternative.icon"
          :data-testid="`icon${alternative.key}`"
          class="multiple-choice__icon"
        />
      </div>
      <div
        v-if="alternative.asFreeText"
        style="max-height: 3rem"
      >
        <v-text-field
          v-model="freeTexts[alternative.key as number]"
          :label="alternative.label"
          :rules="alternative.checked ? [rules.required] : []"
          type="text"
          validate-on="blur"
          variant="underlined"
          :error="error"
          @update:model-value="(value) => textFieldChange(alternative.key as string, value)"
        />
      </div>
      <div
        v-else
        :class="[
          'multiple-choice__alternative-text',
          {
            'multiple-choice__alternative-text--disabled': alternative.disabled,
            'multiple-choice__alternative-text--small-text': smallText
          }
        ]"
      >
        {{ alternative.label }}
      </div>
      <div
        v-if="alternative.sublabel"
        :class="[
          'multiple-choice__alternative-sublabel',
          { 'multiple-choice__alternative-sublabel--disabled': alternative.disabled }
        ]"
      >
        {{ alternative.sublabel }}
      </div>
      <v-checkbox
        density="compact"
        ripple
        :id="`checkbox${alternative.key}`"
        :error="error"
        :name="`checkbox${alternative.key}`"
        :class="[
          'multiple-choice__checkbox',
          { 'multiple-choice__checkbox--top': alternative.asFreeText }
        ]"
        :data-jest="`checkbox${alternative.key}`"
        :data-cy="`checkbox${alternative.key}`"
        :model-value="alternative.checked"
        color="primary"
        hide-details
        :readonly="alternative.readonly"
        :disabled="alternative.disabled"
        :true-icon="alternative.radioIcon ? '$radioOn' : undefined"
        :false-icon="alternative.radioIcon ? '$radioOff' : undefined"
        @update:model-value="(value) => checkboxClick(alternative.key, value)"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { MultipleChoiceAlternative } from "@/types/signup";

export default defineComponent({
  emits: ["select", "freeText"],
  props: {
    error: {
      type: Boolean,
      default: false
    },
    alternatives: {
      type: Array as () => MultipleChoiceAlternative[],
      default: () => [] as MultipleChoiceAlternative[]
    },
    wide: {
      type: Boolean,
      default: false
    },
    smallText: {
      type: Boolean,
      default: false
    },
    marginTop: {
      type: String,
      default: "6rem"
    }
  },
  data() {
    return {
      freeTexts: [] as string[],
      rules: {
        required: (value: string) => !!value || this.$t("required")
      }
    };
  },
  mounted() {
    const freeTextsFields = this.alternatives.filter((a) => a.asFreeText);
    freeTextsFields.forEach((a: MultipleChoiceAlternative) => {
      this.freeTexts[a.key] = a.freeTextValue;
    });
    // Emit initial clicked event for all boxes so that possible listeners
    // can store the initial value correctly
    this.alternatives.forEach((a: MultipleChoiceAlternative) => {
      this.checkboxClick(a.key as string, a.checked === true);
    });
  },
  methods: {
    checkboxClick(key: any, value: any) {
      // // The === check is needed, since value can be null
      this.$emit("select", key, value === true);
    },
    manualClick(key: any, value: any) {
      // // The === check is needed, since value can be null
      this.$emit("select", key, value === true);
    },
    textFieldChange(key: string, value: string) {
      this.freeTexts[key] = value;
      if (value && value.length > 0) {
        if (!this.alternatives.find((a) => a.key === key)?.checked) {
          this.checkboxClick(key, true);
        }
      } else {
        this.checkboxClick(key, false);
      }

      this.$emit("freeText", { key, value });
    }
  }
});
</script>

<style scoped lang="scss">
.multiple-choice {
  flex: 1;
  display: grid;
  grid-template-columns: minmax(0, 20.75rem);
  grid-row-gap: 1rem;
  justify-content: center;
  align-items: center;
  @include medium-up {
    flex-grow: 0;
    grid-column-gap: 4rem;
    grid-row-gap: 0.75rem;
    grid-template-columns: 19.5rem;
    &--wide {
      grid-template-columns: 25rem;
    }
  }
  &--large {
    @include medium-up {
      grid-template-columns: repeat(2, 19.5rem) !important;
    }
  }
  &__alternative {
    display: grid;
    grid-template-columns: auto 1fr auto;
    grid-template-rows: repeat(2, auto);
    width: 100%;
    position: relative;
    border-radius: 0.5rem;
    padding: 0.5625rem;
    align-items: center;
    &--two-col {
      grid-template-columns: 1fr auto !important;
      max-height: 3rem;
    }
  }
  &__alternative-text {
    max-width: 16rem;
    font-size: 1.125rem;
    font-family: $heading-font-family;
    flex: 1;
    grid-row: 1;
    grid-column: 2;
    @include medium-up {
      font-size: 1.25rem;
    }
    &--disabled {
      opacity: 0.4;
    }
    &--small-text {
      font-size: 1.25rem;
    }
  }
  &__alternative-sublabel {
    grid-row: 2;
    grid-column: 2;
    font-size: 1rem;
    font-family: $heading-font-family;
    color: rgba($nearly-black, 0.63);
    &--disabled {
      opacity: 0.4;
    }
  }
  &__checkbox {
    margin: 0 0 0 0.5625rem !important;
    flex-grow: 0;
    z-index: 1;
    padding: 0 !important;
    grid-row: 1;
    grid-column: 3;
    &--top {
      margin: -0.5625rem 0 0 0.5625rem !important;
    }
    .v-input--selection-controls__input {
      margin: 0 !important;
      width: 1.875rem;
      height: 1.875rem;
    }
    .v-icon {
      width: 100%;
      height: 100%;
      border-radius: 0.1875rem;
      transition: box-shadow 0.1s ease-in-out;
    }
    input:focus-visible + .v-icon {
      box-shadow:
        0 0 0.0625rem 0.0625rem $aqua,
        0 0 0.5rem scale-color($aqua, $alpha: -60%),
        inset 0 0 0.25rem scale-color($aqua, $alpha: -60%);
    }
  }
  &__icon {
    color: $soft-blue;
    font-size: 1.2rem;
    @include medium-up {
      color: $dusty-green;
    }
  }
  &__icon-wrapper {
    display: grid;
    place-content: center;
    margin-right: 1rem 0;
    grid-row: 1;
    grid-column: 1;
    margin-right: 1.25rem;
    @include medium-up {
      width: 2.5rem;
      height: 2.5rem;
      background: white;
      border-radius: 50%;
      box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.04);
    }
  }
  &__label {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    border-radius: 0.5rem;
    transition: background 0.1s ease-in-out;
    &:hover {
      @include cursorHover {
        background: rgba(0, 0, 0, 0.04);
      }
    }
  }
  &__ripple {
    color: rgba(0, 0, 0, 0.2);
  }
}
.v-text-field {
  margin-top: 0 !important;
  padding-top: 0 !important;
}
</style>

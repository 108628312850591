import type { ActionTree } from "vuex";
import Kontonummer from "kontonummer";
import type { RootState } from "../../types";
import { UserMutation } from "../user/mutations";
import { PdfMode } from "../signup-navigation/types";
import { SignupNavigationMutation } from "../signup-navigation/mutations";
import { PortfolioMutation } from "../portfolio/mutations";
import { BankIdMutation } from "../bankid/mutations";
import { NavigationMutation } from "../navigation/mutations";
import type { BankAccountAlternative, SignupState } from "./types";
import { SignupMutation } from "./mutations";
import { baseUrl } from "@/clients/config";
import type {
  Lead,
  Portfolio,
  PrepareDocumentSignResponse,
  RegisterUserResponse,
  RiskEvaluation,
  SparData,
  StrategyFocus,
  TimeToWithdraw,
  TinkAccountResponse,
} from "@/clients";
import {
  ApplicationUsersClient,
  Client,
  CreateFrontendStateRequest,
  CreateLead,
  CreatePortfolio,
  CreatePortfolioSettings,
  CreateStrategy,
  CreateUserAffiliateInfo,
  FrontendStateClient,
  LeadClient,
  LegalEntityCountryCode,
  Market,
  PdfClient,
  PortfoliosSettingsClient,
  RegisterUserParameters,
  RiskClient,
  RiskEvaluationParameters,
  SparClient,
  TinkAccountClient,
  TinkRequest,
  UpdateSustainabilityRequest,
} from "@/clients";

import { identifyLead } from "@/clients/segment";
import { downloadFileResponse } from "@/utils/downloader";
import {
  getCreateFirstPortfolioRequest,
  getRegisterUserParameters,
} from "@/mappers/register-user-parameters";

import type { Bank } from "@/types/portfolio";
import { paiFromState } from "@/mappers/pai";

export const SignupAction = {
  createLeadUser: "createLeadUser",
  prepareCreatePortfolio: "prepareCreatePortfolio",
  completeCreatePortfolio: "completeCreatePortfolio",
  prepareCreateFirstPortfolio: "prepareCreateFirstPortfolio",
  completeCreateFirstPortfolio: "completeCreateFirstPortfolio",
  evaluateRisk: "evaluateRisk",
  getPortfolioInvestmentStrategyPdf: "getPortfolioInvestmentStrategyPdf",
  getPortfolioManagementPdfSignup: "getPortfolioManagementPdfSignup",
  getAccountDetails: "getAccountDetails",
  persistStateToBackend: "persistStateToBackend",
  loadStateFromBackend: "loadStateFromBackend",
  loadBankAccountAlternatives: "loadBankAccountAlternatives",
  getSparData: "getSparData",
  validateAffiliateCode: "validateAffiliateCode",
  prepareUpdateEsgSettings: "prepareUpdateEsgSettings",
  updateEsgSettings: "updateEsgSettings",
  registerUserNew: "registerUserNew",
};

const portfoliosSettingsClient = new PortfoliosSettingsClient(baseUrl);
const leadClient = new LeadClient(baseUrl);
const applicationUserClient = new ApplicationUsersClient(baseUrl);
const portfoliosClient = new Client(baseUrl);
const pdfClient = new PdfClient(baseUrl);
const riskClient = new RiskClient(baseUrl);
const tinkAccountClient = new TinkAccountClient(baseUrl);
const stateClient = new FrontendStateClient(baseUrl);
const sparClient = new SparClient(baseUrl);

export const actions: ActionTree<SignupState, RootState> = {
  async [SignupAction.loadBankAccountAlternatives]({ commit, rootState }, payload) {
    if (!rootState.userStore.currentLegalEntity) {
      throw new Error("No current legal entity in state");
    }
    const portfolioSettings = await portfoliosSettingsClient
      .getAll(rootState.userStore.currentLegalEntity.brickId)
      .catch((error) => {
        // TODO Sätt error state, Task: https://trello.com/c/u0lqYvFm.

        console.error(error.message);
      });
    if (portfolioSettings) {
      const alternatives = portfolioSettings
        .filter(x => x.accountNumber !== undefined && x.accountNumber !== null)
        .map(
          setting =>
            ({
              bankName: setting.bankName,
              accountNumber: setting.accountNumber,
              clearingNumber: setting.clearingNumber,
              accountName: setting.accountName,
            }) as BankAccountAlternative,
        );
      const uniqueAlternatives = alternatives.filter(
        (item, index) =>
          alternatives.findIndex(
            other =>
              other.bankName === item.bankName
              && other.accountNumber === item.accountNumber
              && other.clearingNumber === item.clearingNumber,
          ) === index,
      );
      commit(SignupMutation.setBankAccountAlternatives, uniqueAlternatives);
      commit(SignupMutation.setSelectedBankAccountAlternative, undefined);
    }
  },

  async [SignupAction.createLeadUser]({ state, commit }): Promise<Lead | null> {
    const leadUserParams = new CreateLead({
      email: state.contactDetails.email,
      phoneNumber: state.contactDetails.phoneNumber,
      isCompany: false,
      organizationNumber: undefined,
      trackingGuid: undefined,
    });
    const leadUser = await leadClient.create(leadUserParams);
    if (leadUser) {
      identifyLead(leadUser);
      commit(SignupMutation.setTrackingGuid, leadUser.trackingGuid);
    }
    return leadUser;
  },

  async [SignupAction.persistStateToBackend]({ state, rootState }): Promise<string | null> {
    const stateStr = JSON.stringify(rootState);
    const stateResponse = await stateClient.create(
      new CreateFrontendStateRequest({ state: stateStr }),
    );
    if (stateResponse && stateResponse.token) {
      return stateResponse.token;
    }
    return null;
  },

  async [SignupAction.loadStateFromBackend]({ commit, rootState }, token) {
    const stateResponse: any = await stateClient.get(token);
    const stateObj = JSON.parse(stateResponse.state) as any;
    commit(SignupMutation.setState, stateObj.signupStore);
    if (stateObj.userStore) {
      commit(UserMutation.setUserState, stateObj.userStore);
      commit(UserMutation.setToken, stateObj.userStore.token);
    }

    commit(PortfolioMutation.setPortfolioState, stateObj.portfolioStore);
    commit(SignupNavigationMutation.setNavigationState, stateObj.signupNavigationStore);
  },

  async [SignupAction.evaluateRisk]({ state }): Promise<RiskEvaluation | null> {
    const riskParameters = new RiskEvaluationParameters({
      chosenRiskWillingness: state.riskWillingness as number,
      monthlyRemaining: state.monthlyRemainder,
      monthlySalary: state.monthlyIncome,
      strategyFocus: state.focus as StrategyFocus,
      timeToWithdraw: state.timeToWithdraw as TimeToWithdraw,
    });
    return riskClient.evaluateRisk(riskParameters);
  },

  async [SignupAction.prepareCreatePortfolio]({
    state,
    commit,
    getters,
    rootState,
  }): Promise<Portfolio | null> {
    if (!rootState.userStore.currentLegalEntity) {
      throw new Error("no current legal entity in state");
    }
    const strategy = new CreateStrategy({
      chosenRiskLevel: getters.chosenRiskLevel,
      recommendedRiskLevel: state.calculatedRiskLevel as number,
      defenseDiscarded: !state.includedSectors.weaponsAndDefense,
      alcoholTobaccoDiscarded: !state.includedSectors.alcoholTobacco,
      gamblingDiscarded: !state.includedSectors.casinosAndGambling,
      fossilFuelsDiscarded: !state.includedSectors.fossilFuel,
      focus: state.focus as StrategyFocus,
      marketFocus: state.marketFocus || Market.Standard,
      timeToWithdraw: state.timeToWithdraw as TimeToWithdraw,
      esgBiodiversityFilter: !!state.esgFilter?.esgBiodiversityFilter,
      esgEmissionsFilter: !!state.esgFilter?.esgEmissionsFilter,
      esgGovernanceFilter: !!state.esgFilter?.esgGovernanceFilter,
      esgHumanRightsFilter: !!state.esgFilter?.esgHumanRightsFilter,
      esgResourcesFilter: !!state.esgFilter?.esgResourcesFilter,
      tiedAgentPortfolioThemeType: state.portfolioTheme
        ? state.portfolioTheme.tiedAgentPortfolioThemeType
        : undefined,
      esgPais: {
        1: !!state.esgPais.pai1,
        2: !!state.esgPais.pai2,
        3: !!state.esgPais.pai3,
        4: !!state.esgPais.pai4,
        5: !!state.esgPais.pai5,
        7: !!state.esgPais.pai7,
        9: !!state.esgPais.pai9,
        10: !!state.esgPais.pai10,
        11: !!state.esgPais.pai11,
        13: !!state.esgPais.pai13,
        14: !!state.esgPais.pai14,
      },
    });
    const portfolioSettings = new CreatePortfolioSettings({
      initialDeposit: state.firstDeposit as number,
      monthlySaving: state.monthlySavingsAmount as number,
      accountName: state.bankAccountName !== undefined ? state.bankAccountName : "Mitt konto",
      accountNumber: state.bankAccountNumber,
      clearingNumber: state.clearingNumber,
      bankName: state.bankName,
    });
    const portfolio = new CreatePortfolio({
      name: undefined,
      strategy,
      portfolioSettings,
    });
    const response = await portfoliosClient.postApiLegalEntitiesPortfoliosIskPrepare(
      rootState.userStore.currentLegalEntity.brickId,
      portfolio,
    );
    commit(BankIdMutation.setTransactionId, response?.id);
    commit(UserMutation.setAgreementsToSign, response);
    return null;
  },
  async [SignupAction.completeCreatePortfolio]({
    commit,
    rootState,
  }): Promise<Portfolio | null> {
    if (!rootState.userStore.currentLegalEntity) {
      throw new Error("no current legal entity in state");
    }
    if (!rootState.bankIdStore.transactionId) {
      throw new Error("No transactionId in state");
    }
    const response = await portfoliosClient.postApiLegalEntitiesPortfoliosIskComplete(
      rootState.userStore.currentLegalEntity.brickId,
      rootState.bankIdStore.transactionId,
    );
    if (response) {
      commit(UserMutation.setToken, response.token);
      if (!response.portfolio) {
        return null;
      }
      return response.portfolio;
    }
    return null;
  },

  async [SignupAction.prepareCreateFirstPortfolio]({
    state,
    rootState,
    commit,
    getters,
  }): Promise<PrepareDocumentSignResponse | null> {
    if (!rootState.userStore.currentLegalEntity) {
      throw new Error("no current legal entity in state");
    }

    const request = getCreateFirstPortfolioRequest(state, getters);
    try {
      const response = await portfoliosClient.postApiLegalEntitiesPortfoliosPrepareIskFirst(
        rootState.userStore.currentLegalEntity.brickId,
        request,
      );
      commit(BankIdMutation.setTransactionId, response?.id);
      commit(UserMutation.setAgreementsToSign, response);
      return response;
    } catch (e: any) {
      console.error(e);
      throw e;
    }
  },

  async [SignupAction.completeCreateFirstPortfolio]({
    commit,
    rootState,
  }): Promise<Portfolio | null> {
    if (!rootState.userStore.currentLegalEntity) {
      throw new Error("no current legal entity in state");
    }
    if (!rootState.bankIdStore.transactionId) {
      throw new Error("No transactionId in state");
    }
    const response = await portfoliosClient.postApiLegalEntitiesPortfoliosIskFirst(
      rootState.userStore.currentLegalEntity.brickId,
      rootState.bankIdStore.transactionId,
    );
    if (response) {
      commit(UserMutation.setToken, response.token);
      commit(UserMutation.setLegalEntity, response.legalEntity);
      if (!response.portfolio) {
        return null;
      }
      commit(NavigationMutation.setPortfolios, [response.portfolio]);
      return response.portfolio;
    }
    return null;
  },

  async [SignupAction.getPortfolioInvestmentStrategyPdf](
    { state, commit, getters, rootState },
    { pdfId, pdfMode }: { pdfId: number; pdfMode: PdfMode },
  ) {
    commit(SignupMutation.setLoadingPdf, { pdfId, loading: true });

    if (pdfMode === PdfMode.NewPortfolio) {
      if (!rootState.userStore.currentLegalEntity) {
        throw new Error("no current legal entity in state");
      }
      const registerUserParameters = getRegisterUserParameters(
        state,
        getters,
        rootState.userStore.currentLegalEntity as any,
      );
      await pdfClient
        .getPortfolioInvestmentStrategyPdfFromNewPortfolio(
          registerUserParameters,
          rootState.userStore.currentLegalEntity.brickId,
        )
        .then((response: any) => {
          downloadFileResponse(response);
          commit(SignupMutation.setLoadingPdf, { pdfId, loading: false });
        })
        .catch((_) => {
          commit(SignupMutation.setLoadingPdf, { pdfId, loading: false });
        });
    } else {
      const registerUserParameters = getRegisterUserParameters(state, getters);
      await pdfClient
        .getPortfolioInvestmentStrategyPdfFromSignup(registerUserParameters)
        .then((response: any) => {
          downloadFileResponse(response);
          commit(SignupMutation.setLoadingPdf, { pdfId, loading: false });
        })
        .catch((_) => {
          commit(SignupMutation.setLoadingPdf, { pdfId, loading: false });
        });
    }
  },
  async [SignupAction.getAccountDetails]({ getters, commit, state }, code: string): Promise<void> {
    commit(SignupMutation.setLoadingAccountDetails, true);

    const request = new TinkRequest({
      tinkCode: code,
      personalIdentityNumber: getters.formattedPersonalIdentityNumber,
    });

    await tinkAccountClient
      .get(request)
      .then((response: TinkAccountResponse | null) => {
        commit(SignupMutation.setLoadingAccountDetails, false);

        if (response) {
          // Tink test environment does not return any SSN so manually set it here. This is just to get past Signup in dev environment
          if (
            import.meta.env.VITE_ENV === "development"
              && !response.personalIdentityNumber
              && !state.personalIdentityNumber
          ) {
            response.personalIdentityNumber = "197705311467";
          }
          commit(SignupMutation.setBank, response.bank);
          commit(SignupMutation.setFullBankAccountNumber, response.accountNumber);
          commit(SignupMutation.setBankAccountName, response.accountName);
          if (response.personalIdentityNumber) {
            commit(SignupMutation.setPersonalIdentityNumber, response.personalIdentityNumber);
          }

          if (response.accountNumber) {
            // Unfortunately we need to parse the account number since Bricknode only accepts the combined
            // clearing and account number for an external account.
            const kontonummer = new Kontonummer(response.accountNumber, {
              mode: "lax",
            });
            const bank: Bank = {
              bankName: kontonummer.bankName as string,
              accountNumber: kontonummer.accountNumber,
              clearingNumber: kontonummer.sortingCode,
              valid: kontonummer.valid,
            };
            if (bank && bank.valid) {
              commit(SignupMutation.setBankAccountNumber, bank.accountNumber);
              commit(SignupMutation.setClearingNumber, bank.clearingNumber);
              commit(SignupMutation.setClearingNumberParsingError, false);
            } else {
              commit(SignupMutation.setClearingNumberParsingError, true);
            }
          } else {
            commit(SignupMutation.setClearingNumberParsingError, true);
          }
        }
      })
      .catch(() => {
        commit(SignupMutation.setLoadingAccountDetails, false);
        commit(SignupMutation.setTinkError, true);
      });
  },
  async [SignupAction.getSparData]({ state, commit }) {
    await sparClient
      .getSparData(state.personalIdentityNumber)
      .then((response: SparData | null) => {
        if (
          response
          && response.firstName
          && response.lastName
          && response.address
          && response.zipCode
          && response.city
        ) {
          commit(SignupMutation.setSparData, response);
          return Promise.resolve();
        }
        return Promise.reject();
      })
      .catch(_ => Promise.reject());
  },
  async [SignupAction.updateEsgSettings]({ rootState, commit }): Promise<void> {
    if (!rootState.userStore.currentLegalEntity) {
      throw new Error("No current legal entity in state");
    }
    if (!rootState.bankIdStore.transactionId) {
      throw new Error("No current legal entity in state");
    }

    const updatedLegalEntity = await applicationUserClient.completeUpdateSustainability(
      rootState.userStore.currentLegalEntity.brickId,
      rootState.bankIdStore.transactionId,
    );
    if (updatedLegalEntity) {
      commit(UserMutation.setLegalEntity, updatedLegalEntity);
    }
  },
  async [SignupAction.prepareUpdateEsgSettings]({ state, rootState, commit }): Promise<void> {
    if (!rootState.userStore.currentLegalEntity) {
      throw new Error("No current legal entity in state");
    }

    const response = await applicationUserClient.prepareUpdateSustainability(
      rootState.userStore.currentLegalEntity.brickId,
      new UpdateSustainabilityRequest({
        takeSustainabilityImpactIntoAccount: state.takeSustainabilityIntoAccount,
        excludeNegativeSustainabilityImpact: state.sustainabilityImpact
          ?.excludeNegativeImpact as boolean,
        minimizeNegativeSustainabilityImpact: state.sustainabilityImpact
          ?.minimizeNegativeImpact as boolean,
        negativeImpactChanged: state.sustainabilityImpact?.minimizeNegativeImpactChanged as boolean,
        wantsPais: state.takeSustainabilityIntoAccount as boolean,
        excludeWithDisclosureRegulation: state.sustainabilityImpact?.disclosureRegulation,
        excludeWithTaxonomyRegulation: state.sustainabilityImpact?.taxonomyRegulation,
        esgPais: paiFromState(state.esgPais),
      }),
    );
    commit(BankIdMutation.setTransactionId, response?.id);
    commit(UserMutation.setAgreementsToSign, response);
  },
  async [SignupAction.registerUserNew]({ state, rootState, commit }): Promise<void> {
    let response: RegisterUserResponse | undefined;

    try {
      response = await applicationUserClient.registerUser(
        new RegisterUserParameters({
          personalIdentityNumber: state.personalIdentityNumber,
          firstName: state.firstName as string,
          lastName: state.lastName as string,
          address: state.address as string,
          city: state.city as string,
          email: state.contactDetails.email as string,
          phoneNumber: state.contactDetails.phoneNumber as string,
          zipCode: state.zipCode as string,
          isSwedishResident: state.livingInSweden as boolean,
          acceptedPersonalDataPolicy: state.acceptedPersonalDataPolicy,
          referralCode: state.referralCode,
          affiliateInfo: new CreateUserAffiliateInfo({
            affiliateCode: state.affiliateReferralCode,
            affiliateName: state.affiliateReferralName,
          }),
          countryCode:
            rootState.userStore.locale === "no"
              ? LegalEntityCountryCode.No
              : LegalEntityCountryCode.Se,
          tiedAgentType: state.tiedAgent?.tiedAgentType,
        }),
      );
    } catch (error: any) {
      commit(UserMutation.setToken, undefined);

      console.error(error);
      throw error;
    }
    commit(UserMutation.setToken, response.token);
    commit(
      SignupMutation.setRegisteredUserApproved,
      response.applicationUser?.legalEntityUsers.every(x => x.legalEntity?.passedIsApprovedCheck),
    );

    if (!response) {
      throw new Error("Failed to create user");
    }
  },
};

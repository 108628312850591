<template>
  <div class="market-focus">
    <SingleChoice
      :alternatives="navigationAlternatives"
      information
      @click="goToNext"
      @show-information="showDialog = true"
    >
      <QuestionTextSection
        :heading="heading"
        :body="$t('common-signup.questions.preferences.market-focus.body')"
        progress
      />

      <div class="market-focus__content">
        <v-btn
          variant="text"
          icon
          :color="softBlue"
          class="market-focus__stepper"
          :disabled="carouselIndex === 0"
          data-cy="stepper-left"
          @click="decrement"
        >
          <icon :icon="['fal', 'chevron-left']" />
        </v-btn>
        <v-carousel
          v-model="carouselIndex"
          class="market-focus__carousel"
          height="17rem"
          data-cy="risk_carousel"
          :show-arrows="false"
          :continuous="false"
          hide-delimiter-background
          @update:model-value="change"
        >
          <v-carousel-item
            v-for="focus in alternatives"
            :key="focus.key"
            class="market-focus__risk-carousel-item"
          >
            <v-card
              style="
                width: 13.75rem;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 1.5rem;
                row-gap: 0.5rem;
              "
            >
              <div style="font-weight: bold; font-size: 1.125rem">
                {{ focus.label }}
              </div>
              <v-divider style="width: 100%; border-color: #2b2b2b; opacity: 0.33" />

              <div style="width: 100%; display: flex; flex-direction: column; row-gap: 0.2rem">
                <div class="market-focus__row">
                  <div style="font-size: 1.125rem">
                    {{ $t("markets.Sweden") }}
                  </div>
                  <div style="font-weight: bold; font-size: 1.125rem">
                    {{ getMarketDistributionPercentages()[Market.Sweden] }} %
                  </div>
                </div>
                <div class="market-focus__row">
                  <div style="font-size: 1.125rem">
                    {{ $t("markets.Europe") }}
                  </div>
                  <div style="font-weight: bold; font-size: 1.125rem">
                    {{ getMarketDistributionPercentages()[Market.Europe] }} %
                  </div>
                </div>
                <div class="market-focus__row">
                  <div style="font-size: 1.125rem">
                    {{ $t("markets.Us") }}
                  </div>
                  <div style="font-weight: bold; font-size: 1.125rem">
                    {{ getMarketDistributionPercentages()[Market.Us] }} %
                  </div>
                </div>
              </div>
              <v-divider style="width: 100%; border-color: #2b2b2b; opacity: 0.33" />
              <div style="display: flex; flex-direction: column; row-gap: 0; width: 100%">
                <div class="market-focus__row">
                  <div style="font-size: 0.75rem">
                    {{ $t("managementFee") }}
                  </div>
                  <div style="font-size: 0.875rem">
                    {{ managementFee }} %
                  </div>
                </div>
                <div
                  class="market-focus__row"
                  style="margin-top: -0.3rem"
                >
                  <div style="font-size: 0.6875rem">
                    {{ $t("perYear") }}
                  </div>
                </div>
              </div>
            </v-card>
          </v-carousel-item>
        </v-carousel>
        <v-btn
          variant="text"
          icon
          :color="softBlue"
          class="market-focus__stepper"
          :disabled="carouselIndex === alternatives.length - 1"
          data-cy="stepper-right"
          @click="increment"
        >
          <icon :icon="['fal', 'chevron-right']" />
        </v-btn>
      </div>
      <MarketFocusSideDialog v-model="showDialog" />
    </SingleChoice>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import QuestionTextSection from "../../../signup/question-text-section.vue";
import SingleChoice from "../../../signup/templates/single-choice.vue";
import { SignupNavigationAction } from "@/store/signup-navigation/actions";
import MarketFocusSideDialog from "@/components/market-focus-side-dialog.vue";
import { Market } from "@/clients";
import type { NavigationAlternative } from "@/types/signup";
import { softBlue } from "@/styles/colors";
import getMarketDistribution from "@/utils/market-distribution";
import Question from "@/views/signup/templates/question";

interface MarketFocusAlternative {
  label: string;
  key: number;
}

export default defineComponent({
  components: {
    QuestionTextSection,
    SingleChoice,
    MarketFocusSideDialog,
  },
  extends: Question,
  props: {
    marketFocus: { type: Number },
    heading: { type: String, required: true },
    managementFee: { type: String, required: true },
  },
  emits: ["change"],
  data: () => ({
    showDialog: false,
    carouselIndex: 0,
    Market,
    softBlue,
  }),
  computed: {
    alternatives(): MarketFocusAlternative[] {
      const result = [
        {
          label: this.$t("marketFocusStandard"),
          key: Market.Standard,
        },
        {
          label: this.$t("sweden"),
          key: Market.Sweden,
        },
        {
          label: this.$t("marketFocusEurope"),
          key: Market.Europe,
        },
        {
          label: this.$t("usa"),
          key: Market.Us,
        },
      ];

      return result;
    },
    navigationAlternatives(): NavigationAlternative[] {
      return [
        {
          text: `${this.$t("choose")} ${
            this.alternatives && this.marketFocus !== undefined
              ? this.alternatives.find(x => x.key === this.marketFocus)?.label
              : ""
          }`,
          key: "next",
        },
      ];
    },
  },
  created(): void {
    if (this.marketFocus !== undefined) {
      // Spin carousel to already selected market's index
      this.carouselIndex = this.alternatives.findIndex(x => x.key === this.marketFocus);
      // Manually trigger change because v-carousel event is only triggered by user interaction with actual component
      this.change();
    } else {
      // Simulate an initial selection so parent can take action
      this.change();
    }
  },
  methods: {
    increment() {
      this.carouselIndex += 1;
      // Manually trigger change because v-carousel event is only triggered by user interaction with actual component
      this.change();
    },
    decrement() {
      this.carouselIndex -= 1;
      // Manually trigger change because v-carousel event is only triggered by user interaction with actual component
      this.change();
    },
    change(): void {
      this.$emit("change", this.alternatives[this.carouselIndex].key);
    },
    goToNext() {
      this.$store.dispatch(SignupNavigationAction.goToNextStep, this.$router);
    },
    getMarketDistributionPercentages(): any {
      return getMarketDistribution(this.marketFocus as Market);
    },
  },
});
</script>

<style lang="scss" scoped>
.market-focus {
  width: 100%;
  height: 100%;
  font-family: sofia-pro;
  &__row {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  &__carousel {
    width: 13.75rem;
    :deep(.v-carousel__controls) {
      color: $soft-blue;
      @include medium-up {
        bottom: -0.5rem;
      }
      .v-btn {
        width: 0.75rem;
        height: 0.75rem;
        opacity: 0.5;
        &--active {
          opacity: 1;
        }
      }
      .v-icon {
        font-size: 0.5rem !important;
        width: 0.75rem !important;
        height: 0.75rem !important;
      }
    }
    &:deep(.v-carousel__item) {
      margin: 1rem;
      box-shadow: 0 0.1875rem 1.25rem rgba(96, 96, 96, 0.1);
    }
  }
  &__content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 21rem;
    margin: auto;
    margin-top: 1rem;
  }
  &__presentation {
    display: flex;
    margin-bottom: 2rem;
  }
  &__risk-carousel-item {
    margin: auto;
  }
  &__choices-carousel-item {
    width: 21.25rem;
    max-width: 100%;
    margin: auto;
  }

  &__side-dialog {
    padding: 1rem;
    &--description {
      font-weight: 300;
    }
  }
  &__wrapper {
    max-width: 20em;
    background: transparent !important;
    margin: 0 auto;
    padding-top: 1rem;
    @include medium-up {
      padding-top: 2rem;
    }
  }
  &__alternative {
    display: grid;
    grid-template-columns: auto 1fr auto;
    width: 100%;
    position: relative;
    border-radius: 0.5rem;
    padding: 0.5625rem;
    align-items: center;
  }
  &__alternative-text {
    font-size: 1.125rem;
    font-family: $heading-font-family;
    flex: 1;
    grid-column: 2;
    padding: 0 2.25rem 0 1rem;
    @include medium-up {
      font-size: 1.25rem;
    }
  }
  &__percentage {
    grid-column: 3;
    font-size: 1.125rem;
    font-family: $heading-font-family;
    color: $nearly-black;
    font-weight: 600;
  }

  &__label {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    border-radius: 0.5rem;
    transition: background 0.1s ease-in-out;
    &:hover {
      @include cursorHover {
        background: rgba(0, 0, 0, 0.04);
      }
    }
  }
  &__stepper {
    font-size: 1.5rem;
    outline: none;
    &--right {
      justify-self: flex-end;
    }
    &:before {
      background: transparent;
      transition: all 0.1s ease-in-out;
    }
    &:hover:before {
      @include cursorHover {
        background: black;
      }
    }
    &:focus-visible {
      box-shadow:
        0 0 0.0625rem 0.0625rem $aqua,
        0 0.125rem 0.875rem scale-color($aqua, $alpha: -60%);
    }
  }
}

:deep(
    .theme--light.v-input--selection-controls.v-input--is-disabled:not(
        .v-input--indeterminate
      )
  )
  .v-icon {
  color: rgba(0, 0, 0, 0.54) !important;
}
</style>

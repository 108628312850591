<template>
  <div class="risk-level">
    <h2 class="risk-level__heading">
      {{ $t("riskLevel") }}
    </h2>
    <div class="risk-level__risk-selection">
      <transition
        name="fade"
        mode="out-in"
      >
        <v-btn
          v-if="editRiskLevel"
          variant="flat"
          icon
          size="x-small"
          class="risk-level__stepper"
          :disabled="chosenRiskLevel === 3"
          data-cy="stepper-left"
          @click="decrement"
        >
          <icon :icon="['fal', 'chevron-left']" />
        </v-btn>
      </transition>
      <p class="risk-level__risk risk-level__risk--left">
        {{ chosenRiskLevel }}
      </p>
      <p class="risk-level__risk risk-level__risk--center">/</p>
      <p class="risk-level__risk">
        {{ maximumRiskLevel }}
      </p>
      <transition
        name="fade"
        mode="out-in"
      >
        <v-btn
          v-if="editRiskLevel"
          variant="flat"
          icon
          size="x-small"
          class="risk-level__stepper risk-level__stepper--right"
          data-cy="stepper-right"
          :disabled="chosenRiskLevel === maximumRiskLevel"
          @click="increment"
        >
          <icon :icon="['fal', 'chevron-right']" />
        </v-btn>
      </transition>
    </div>
    <p class="risk-level__percentage">
      {{ `${stocksPercentage} % ${$t("stocksLower")}` }}
    </p>
    <p class="risk-level__percentage">
      {{ `${etfsPercentage} % ${$t("etfsLower")}` }}
    </p>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import { SignupMutation } from "@/store/signup/mutations";
import { calculateRisk, nextRiskLevel } from "@/config/risk-levels";

export default defineComponent({
  emits: ["setCustomRiskLevel"],
  props: {
    editRiskLevel: {
      type: Boolean,
      default: false
    },
    calculatedRiskLevel: { type: Number, required: true },
    chosenRiskLevel: { type: Number, required: true },
    stocksFraction: { type: Number, required: true },
    marketFocus: { type: Number, required: false, default: undefined },
    includedSectors: { type: Object, required: true }
  },
  computed: {
    // ...mapGetters(["chosenRiskLevel", "maximumRiskLevel", "stocksFraction"]),
    ...mapGetters(["maximumRiskLevel"]),
    stocksPercentage(): number {
      const { interestFraction, stocksFraction } = calculateRisk(this.riskLevel);
      return Math.round(stocksFraction * 100);
    },
    etfsPercentage(): number {
      return 100 - this.stocksPercentage;
    },
    riskLevel: {
      get(): number {
        return this.chosenRiskLevel;
      },
      set(value: number) {
        // this.$store.commit(SignupMutation.setCustomRiskLevel, value);
        this.$emit("setCustomRiskLevel", value);
      }
    }
    // calculatedRiskLevel: {
    //   get(): number {
    //     return this.$store.state.calculatedRiskLevel;
    //   }
    // }
  },
  methods: {
    decrement(_: MouseEvent) {
      this.riskLevel = nextRiskLevel(this.riskLevel, false);
    },
    increment(_: MouseEvent) {
      this.riskLevel = nextRiskLevel(this.riskLevel, true);
    }
  }
});
</script>

<style lang="scss" scoped>
.risk-level {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.75rem 0;
  @include medium-up {
    padding: 1.625rem 0;
  }
  &__heading {
    font-size: 1rem;
    color: $soft-blue;
    font-weight: 600;
    margin: 0;
    line-height: 1rem;
  }
  &__risk-selection {
    display: grid;
    grid-template-columns: 1fr 2rem auto 2rem 1fr;
    align-items: center;
    width: 9.5rem;
    justify-content: space-between;
    margin: 0 1.125rem;
  }
  &__risk {
    font-weight: 600;
    font-family: $heading-font-family;
    font-size: 3rem;
    margin: -0.5rem 0 -0.25rem 0;
    flex-grow: 1;
    user-select: none;
    &--left {
      grid-column: 2;
      text-align: right;
    }
    &--center {
      text-align: center;
    }
  }
  &__percentage {
    font-weight: 300;
    font-family: $heading-font-family;
    font-size: 0.875rem;
    line-height: 1rem;
    margin-bottom: 0.25rem;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  &__stepper {
    font-size: 1.125rem;
    outline: none;
    background-color: transparent !important;

    &--right {
      justify-self: flex-end;
    }
    &:before {
      background: transparent;
      transition: all 0.1s ease-in-out;
    }
    &:hover:before {
      @include cursorHover {
        background: black;
      }
    }
    :deep(.v-btn__overlay) {
      opacity: 0;
    }
    &:focus-visible {
      box-shadow:
        0 0 0.0625rem 0.0625rem $aqua,
        0 0.125rem 0.875rem scale-color($aqua, $alpha: -60%);
    }
  }
}
</style>
